<template>
  <div class="row" v-if="!$wait.waiting('getEventsScore')">

    <div class="col px-0 px-lg-3">
      <div class="shadow rounded bg-white py-3 py-md-4 px-lg-2 mb-4">

        <div class="col d-flex flex-wrap align-items-center mb-3 justify-content-between">
          <span class="h5 mr-3"><i class="nav-icon icons8-stats"></i> {{ title }}</span>
          <button @click="exportToExcel" class="btn btn-success" type="button">
            <i class="icons8-download"></i>
            Экспорт в Excel
          </button>
        </div>

        <div class="col">
          <v-client-table :columns="columns"
                          :options="tableOptions"
                          :data="eventsScore"
                          :name="'score-event-list'">
            <router-link slot="name"
                         slot-scope="props"
                         :to="eventDetailLink( props.row.id )">
              {{ props.row.name }}
            </router-link>
            <router-link :to="eventScoreLink( props.row.id )"
                         v-if="props.row.visiters_total > 0"
                         slot="action"
                         slot-scope="props"
                         class="btn btn-outline-primary py-0">
              Подробнее
            </router-link>
          </v-client-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import pick from 'lodash/pick'
  import XLSX from 'xlsx'

  export default {
    name: 'ScoreEventList',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: 'Статистика',
        eventsScore: [],
        eventTypes: [],
        columnsSettings:
          [
            {
              name: 'Мероприятие',
              alias: 'name',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Дата',
              alias: 'opens_from',
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Тип',
              alias: 'eventType_name', // relationName_relationField
              sortable: true,
              filterable: true,
              visible: true,
            },
            {
              name: 'Посетило',
              alias: 'visiters_total',
              sortable: true,
              filterable: false,
              visible: true,
            },
            {
              name: '',
              alias: 'action',
              sortable: false,
              filterable: false,
              visible: true,
            },
          ],
      }
    },

    computed: {
      tableOptions() {
        return {
          saveState: true,
          filterByColumn: true,
          skin: 'table table-bordered table-striped',
          filterable: this.filterableColumns,
          sortable: this.sortableColumns,
          headings: this.headings,
          perPage: 10,
          perPageValues: [10],
          pagination: { chunk: this.$store.getters.windowSize === 'large' ? 10 : 3 },
          columnsDropdown: false,
          orderBy: {
            column: 'opens_from',
            ascending: false,
          },
          columnsClasses: {
            id: 'mw-100',
          },
          listColumns: {
            eventType_name: this.eventTypes,
          },
          sortIcon: {
            base: 'icons8',
            up: 'icons8-chevron-up',
            down: 'icons8-chevron-down',
            is: 'icons8-up-and-down-arrows',
          },
        }
      },

      filterableColumns() {
        return this.columnsSettings.filter( column => column.filterable ).map( column => column.alias )
      },

      sortableColumns() {
        return this.columnsSettings.filter( column => column.sortable ).map( column => column.alias )
      },

      headings() {
        let result = {}

        this.columnsSettings.forEach( column => {
          result[column.alias] = column.name
        } )

        return result
      },

      columns() {
        return this.columnsSettings.filter( column => column.visible ).map( column => column.alias )
      },
    },

    created() {
      this.$wait.start( 'findAllEventTypes' )

      this.$api.eventType.findAllEventTypes().then( res => {
        if ( res.status === 200 && res.data.data ) {
          this.eventTypes = res.data.data.map( item => ( { id: item.name, text: item.name } ) )
        }
      } ).finally( () => {
        this.$wait.end( 'findAllEventTypes' )
      } )

      this.$wait.start( 'getEventsScore' )

      this.$api.event.getEventsScore().then( res => {
        if ( res.status === 200 && res.data.data ) {
          let eventsScore = res.data.data

          // vue-tables-2 dont support nested filtering
          eventsScore.forEach( event => {
            if ( event.event_type.data ) {
              this.$set( event, 'eventType_name', event.event_type.data.name )
            }
          } )

          this.eventsScore = eventsScore
        }
      } ).finally( () => {
        this.$wait.end( 'getEventsScore' )
      } )
    },

    methods: {
      eventDetailLink( id ) {
        return `/events/${id}`
      },

      eventScoreLink( eventId ) {
        return `/score/${eventId}`
      },

      exportToExcel() {
        let formatted = this.eventsScore.map( item => {
          return pick( item, ['name', 'opens_from', 'eventType_name', 'visiters_total'] )
        } )

        let scoreWS = XLSX.utils.json_to_sheet( formatted, {
          header: ['name', 'opens_from', 'eventType_name', 'visiters_total'],
          skipHeader: false,
        } )

        let now = new Date().toLocaleDateString( 'ru', {
          timezone: 'Europe/Moscow',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        } )

        scoreWS.A1.v = 'Мероприятие'
        scoreWS.B1.v = 'Дата'
        scoreWS.C1.v = 'Тип'
        scoreWS.D1.v = 'Посетило'

        scoreWS['!cols'] = [
          { wch: 40 },
          { wch: 20 },
          { wch: 10 },
          { wch: 10 },
        ]

        let workbook = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet( workbook, scoreWS, 'score' )
        XLSX.writeFile( workbook, `events_score_to_${now}.xlsx` )
      },
    },
  }
</script>
